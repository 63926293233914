<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Programação por Unidade">
          <v-row class="grey lighten-4" v-if="canList">
            <v-col cols="3" sm="3">
              <form-programacao-unidade
                ref="formulario"
                @pesquisar="pesquisar"
                @prev="calendarPrev"
                @next="calendarNext"
              />
            </v-col>
            <v-col cols="9" sm="9" class="grey lighten-5">
              <calendario-programacao-unidade ref="calendario" />
            </v-col>
          </v-row>
          <v-row v-if="!canList">
            <v-col cols="12" sm="12" align="center">
              <span class="red--text"
                >Você não tem permissão para visualizar as informações desta
                tela</span
              >
            </v-col>
          </v-row>
        </BaseCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" align="center">
        <v-btn color="primary" small @click="verRelatorio">
          Visualizar distribuição
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <cotas-programacao-unidade
          ref="cotas"
          v-show="relatorio"
          v-bind:calendario="calendario"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormProgramacaoUnidade from '@/components/patio/Programacao/Unidade/Form.vue'
import CalendarioProgramacaoUnidade from '@/components/patio/Programacao/Unidade/Calendario.vue'
import CotasProgramacaoUnidade from '@/components/patio/Programacao/Unidade/Cotas.vue'
import { DAYS, TIME_DAYS } from '@/mixins/days.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  data() {
    return {
      showForm: false,
      editarTransportadora: false,
      relatorio: false,
      page: {
        title: 'Gerenciamento de programação por Unidade',
      },
      breadcrumbs: [
        {
          text: 'Programação por Unidade',
          disabled: true,
        },
      ],
      loading: false,
    }
  },
  components: {
    FormProgramacaoUnidade,
    CalendarioProgramacaoUnidade,
    CotasProgramacaoUnidade,
  },
  computed: {
    ...mapState('programacao', ['params']),

    canList() {
      return this.$canDo(
        BasicPermissions.VISUALIZAR,
        Sequences.CotasUnidade.toString()
      )
    },

    calendario: function () {
      return this.$refs.calendario
    },
  },
  async created() {
    this.DAYS = DAYS
    this.loading = true
    if (!this.params.data_inicio) {
      const data_inicio = new Date(
        new Date(new Date().setHours(0, 0, 0, 0)).setDate(
          new Date().getDate() - new Date().getDay()
        )
      )
        .toISOString()
        .split('T')[0]
      this.SET_PARAMS({ data_inicio })
    }
    await this.getProgramacoesUnidade(this.params)
    this.loading = false
  },
  mounted() {
    this.mesAno()
    this.changeHeadersDay()
  },
  methods: {
    ...mapActions('programacao', ['getProgramacoesUnidade']),
    ...mapMutations('programacao', ['SET_PARAMS']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async verRelatorio() {
      if (Object.entries(this.params).length == 0) {
        this.errorMessage('Pesquise alguma informação primeiro!')
        return
      }
      await this.$refs.cotas.atualizarEstrutura(this.params)
      this.relatorio = true
    },

    mesAno() {
      window.calendar = { ...this.$refs.calendario.$refs.calendar }
      let meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ]
      this.$refs.formulario.mes =
        meses[this.$refs.calendario.$refs.calendar.lastStart.month - 1]
      this.$refs.formulario.ano = this.$refs.calendario.$refs.calendar.lastStart.year
    },
    changeHeadersDay() {
      let calendar = this.$refs.calendario.$refs.calendar
      let semana = [
        this.DAYS.DOMINGO,
        this.DAYS.SEGUNDA,
        this.DAYS.TERCA,
        this.DAYS.QUARTA,
        this.DAYS.QUINTA,
        this.DAYS.SEXTA,
        this.DAYS.SABADO,
      ]
      let start = calendar.lastStart
      let data = new Date(start.date)
      let newHeader = []
      semana.forEach((dia) => {
        let diff = data.getUTCDay() - dia
        let nova_data = new Date(data.getTime() - TIME_DAYS(diff))
        let new_data = nova_data.toISOString().split('T')[0].split('-')
        let new_text = new_data.reverse().join('/')
        newHeader.push({
          text: new_text,
          value: new_text,
        })
      })
      this.$refs.cotas.changeHeadersDay(newHeader)
    },
    async calendarPrev() {
      await this.$refs.calendario.$refs.calendar.prev()
      this.mesAno()
      this.changeHeadersDay()
    },
    async calendarNext() {
      await this.$refs.calendario.$refs.calendar.next()
      this.mesAno()
      this.changeHeadersDay()
    },
    async pesquisar(params) {
      this.SET_PARAMS(params)
      await this.$refs.calendario.atualizarListaProgramacoesUnidade(params)
      // if (this.relatorio) {
      //   await this.$refs.cotas.atualizarEstrutura(params)
      // }
    },
  },
}
</script>

<style></style>
