<template>
  <div>
    <div class="d-flex justify-space-between mt-3 mb-6">
      <v-btn icon class="ma-2" @click="$emit('prev')">
        <v-icon large color="blue">mdi-chevron-left-circle</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <span class="mt-3 subtitle-1">{{ mes }}/{{ ano }}</span>
      <v-spacer></v-spacer>
      <v-btn icon class="ma-2" @click="$emit('next')">
        <v-icon large color="blue">mdi-chevron-right-circle</v-icon>
      </v-btn>
    </div>
    <div>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" v-if="!this.empresaAtual.gate">
          <v-autocomplete
            v-model="params.gate"
            label="Gate"
            :items="selectGate"
            :item-text="
              (item) =>
                item.business_name +
                ' - ' +
                `${item.cnpj != null ? item.cnpj : item.cpf}`
            "
            item-value="public_id"
            @change="selectedGate()"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-autocomplete
            v-model="params.tipo_operacao"
            label="Tipo de operação"
            :items="selectTipoOperacao"
            item-text="descricao"
            item-value="id"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-autocomplete
            v-model="params.fornecedor"
            label="Fornecedor"
            :items="selectFornecedor"
            :item-text="
              (item) =>
                item.business_name +
                ' - ' +
                `${item.cnpj != null ? item.cnpj : item.cpf}`
            "
            item-value="public_id"
            @change="selectedFornecedor()"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-autocomplete
            v-model="params.terminal"
            label="Terminal"
            :item-text="
              (item) =>
                item.business_name +
                ' - ' +
                `${item.cnpj != null ? item.cnpj : item.cpf}`
            "
            item-value="public_id"
            :items="selectTerminal"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-autocomplete
            v-model="params.produto"
            label="Produto"
            item-text="descricao"
            item-value="id"
            :items="selectProduto"
            @change="selectedProduto()"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-autocomplete
            v-model="params.sub_produto"
            item-text="descricao"
            label="Sub Produto"
            item-value="id"
            :items="selectSubProduto"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="params.data_inicio"
                label="De"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.data_inicio"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="params.data_fim"
                label="Até"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.data_fim"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12">
          <v-radio-group v-model="params.tipo" row>
            <v-radio label="Tonelada" value="ton"></v-radio>
            <v-radio label="Quantidade" value="qtd"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <v-btn color="primary" small @click="$emit('pesquisar', params)">
          Pesquisar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ContratoAPI from '@/services/tenants/contratos'
import Perfil from '@/utils/perfil'
import { preferencias } from '@/mixins/preferencias'

export default {
  name: 'FormProgramacaoUnidade',
  mixins: [preferencias],
  data() {
    return {
      dialog: false,
      tab: null,
      mes: '',
      ano: '',
      menu1: false,
      menu2: false,
      selectGate: [],
      selectTipoOperacao: [],
      selectFornecedor: [],
      selectProduto: [],
      selectSubProduto: [],
      selectTerminal: [],
      programacoes: [],
      params: {
        gate: null,
        tipo_operacao: null,
        fornecedor: null,
        produto: null,
        sub_produto: null,
        terminal: null,
        data_inicio: null,
        data_fim: null,
      },
    }
  },
  computed: {
    ...mapState('patio', [
      'programacaoUnidade',
      'programacoesUnidade',
      'terminais',
      'tiposOperacao',
    ]),
    ...mapState('subProdutos', ['subProdutos']),
    ...mapState('produtos', ['produtos']),
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),
    allowedToAdd() {
      return this.empresaAtual.gate
    },
  },
  async created() {
    if (this.empresaAtual.gate) {
      this.selectGate = [this.empresaAtual]
      this.params.gate = this.empresaAtual
      this.SET_PARAMS({ gate: this.empresaAtual })
      this.selectedGate()
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      if (this.preferencias?.gate) {
        if (this.preferencias.gate?.public_id) {
          this.params.gate = this.preferencias.gate
        } else {
          this.params.gate = possible_gates.find(
            (gate) => gate.public_id == this.preferencias.gate
          )
        }
        if (this.params.gate) {
          this.selectedGate()
        }
      }
      this.selectGate = [
        { business_name: 'Nenhum', cnpj: '0', public_id: null },
        ...possible_gates,
      ]
    }
  },
  methods: {
    ...mapActions('patio', ['getProgramacoesUnidade', 'getTipoOperacao']),
    ...mapActions('fornecedores', [
      'getFornecedores',
      'getFornecedoresByParams',
    ]),

    ...mapMutations('patio', ['setTerminal']),
    ...mapMutations('produtos', ['setProduto']),
    ...mapMutations('subProdutos', ['setSubProduto']),
    ...mapMutations('programacao', ['SET_PARAMS']),

    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async selectedGate() {
      if (this.params.gate?.public_id != null) {
        if (this.preferencias) {
          this.preferencias.gate = this.params.gate
        }
        await this.getTipoOperacao({ public_id: this.params.gate.public_id })
        this.selectTipoOperacao = [
          { descricao: 'Nenhum', id: null },
          ...this.tiposOperacao,
        ]
        await this.atualizarListaFornecedores()
      }
    },
    async atualizarListaFornecedores() {
      try {
        if (!this.empresaAtual.gate) {
          const fornecedores = await this.getFornecedoresByParams({
            params: {
              fornecedor: this.empresaAtual.public_id,
            },
            empresa: this.params.gate,
          })
          this.selectFornecedor = [
            { business_name: 'Nenhum', cnpj: '0', public_id: null },
            ...fornecedores,
          ]
        } else {
          await this.getFornecedores({ public_id: this.params.gate.public_id })
          this.selectFornecedor = [
            { business_name: 'Nenhum', cnpj: '0', public_id: null },
            ...this.fornecedores,
          ]
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não é gate para ter fornecedores.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    async atualizarprogramacoesUnidade() {
      try {
        await this.getProgramacoesUnidade({
          params: null,
        })
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso ao setor.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    async selectedFornecedor() {
      if (this.params.fornecedor != null) {
        if (this.params.fornecedor.public_id != null) {
          this.setProduto(this.params.fornecedor.produtos)
          this.selectProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.produtos,
          ]
          this.setTerminal(
            await ContratoAPI.getContratos(
              this.params.fornecedor.fornecedor_id,
              {
                perfil: Perfil.Terminal,
              }
            )
          )
          this.selectTerminal = [
            { business_name: 'Nenhum', cnpj: '0', public_id: null },
            ...this.terminais,
          ]
        } else {
          this.selectProduto = [{ descricao: 'Nenhum', id: null }]
          this.selectTerminal = [
            { business_name: 'Nenhum', cnpj: '0', public_id: null },
          ]
        }
      } else {
        this.selectProduto = [{ descricao: 'Nenhum', id: null }]
        this.selectTerminal = [
          { business_name: 'Nenhum', cnpj: '0', public_id: null },
        ]
      }
      this.SET_PARAMS({ produto: null, sub_produto: null, terminal: null })
      this.selectedProduto()
    },
    selectedProduto() {
      if (this.params.produto != null) {
        if (this.params.produto.id != null) {
          this.setSubProduto(this.params.produto.subprodutos)
          this.selectSubProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.subProdutos,
          ]
        } else {
          this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
          this.SET_PARAMS({ sub_produto: null })
        }
      } else {
        this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
        this.SET_PARAMS({ sub_produto: null })
      }
    },
  },
}
</script>
