<template>
  <div>
    <v-sheet tile height="54" class="d-flex">
      <v-btn outlined class="ma-2" color="grey darken-2" @click="setToday">
        Hoje
      </v-btn>
      <v-autocomplete
        v-model="type"
        :items="types"
        item-text="text"
        item-value="value"
        dense
        outlined
        hide-details
        class="ma-2"
        label="Tipos"
      ></v-autocomplete>
      <v-autocomplete
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="Dias da Semana"
        class="ma-2"
      ></v-autocomplete>
      <v-btn
        outlined
        class="ma-2"
        color="grey darken-2"
        @click="atualizarListaProgramacoesUnidade()"
      >
        Atualizar
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @change="getEvents"
        @mousedown:event="startDrag"
        @mousedown:time="startTime"
        @mousemove:time="mouseMove"
        @mouseup:time="endDrag"
        @mouseleave.native="cancelDrag"
      >
        <template v-slot:event="{ event, timed, eventSummary }">
          <div class="v-event-draggable" v-html="eventSummary()"></div>
          <div class="ma-2">
            <b>Restante:</b>
            <table
              style="width: 90%; background: white; color: black; margin: 10px"
              border="1px"
            >
              <thead>
                <tr>
                  <th style="padding: 1px">Produto</th>
                  <th style="padding: 1px">Terminal</th>
                  <th style="padding: 1px">Designado</th>
                  <th style="padding: 1px">distribuidos</th>
                  <th style="padding: 1px">Restante</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="event.item.qt_caminhoes > 0">
                  <td style="padding: 1px">
                    {{ event.item.produto.descricao }}
                  </td>
                  <td style="padding: 1px">
                    {{ event.item.terminal.trade_name }}
                  </td>
                  <td style="padding: 1px">
                    {{
                      event.item.qt_caminhoes ? event.item.qt_caminhoes : '0'
                    }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_distribuida_caminhoes(event.item) }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_restante_caminhoes_a_distribuir(event.item) }}
                  </td>
                </tr>
                <tr v-if="event.item.qt_peso > 0">
                  <td style="padding: 1px">
                    {{ event.item.produto.descricao }}
                  </td>
                  <td style="padding: 1px">
                    {{ event.item.terminal.trade_name }}
                  </td>
                  <td style="padding: 1px">
                    {{ event.item.qt_peso ? event.item.qt_peso : '0' }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_distribuida_peso(event.item) }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_restante_peso_a_distribuir(event.item) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
          </div>
          <ul>
            <li class="font-weight-black">
              {{
                event.item.tipo_operacao
                  ? event.item.tipo_operacao.descricao
                  : 'Sem operação!'
              }}
            </li>
            <li>
              {{
                event.item.fornecedor
                  ? event.item.fornecedor.trade_name
                  : 'Sem fornecedor!'
              }}
            </li>
            <li v-if="event.item.terminal">
              {{ event.item.terminal.trade_name }}
            </li>
            <li v-if="event.item.produto">
              {{ event.item.produto.descricao }}
            </li>
            <li v-if="event.item.sub_produto">
              {{ event.item.sub_produto.descricao }}
            </li>
            <li>Peso: {{ event.item.qt_peso ? event.item.qt_peso : '0' }}</li>
            <li>
              Caminhões:
              {{ event.item.qt_caminhoes ? event.item.qt_caminhoes : '0' }}
            </li>
          </ul>

          <div
            v-if="timed"
            class="v-event-drag-bottom"
            @mousedown.stop="extendBottom(event)"
          ></div>
        </template>
      </v-calendar>
    </v-sheet>
    <v-dialog
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-row class="mt-0">
                <v-col class="pt-0 d-flex justify-space-between" cols="12">
                  <div class="d-flex flex-column my-5">
                    <span class="">{{ selectedEvent.name }}</span>
                    <span class="text-caption">{{
                      editedItem.public_id || 'Não criada'
                    }}</span>
                  </div>

                  <suspensao-programacao-unidade
                    v-if="editavel"
                    ref="suspensao"
                    @cls="close"
                    @att="atualizarListaProgramacoesUnidade"
                    v-bind:programacaoUnidade="editedItem"
                  ></suspensao-programacao-unidade>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="proprietario"
                    rules="required"
                  >
                    <v-autocomplete
                      v-if="!empresaAtual.gate"
                      v-model="editedItem.proprietario"
                      label="Gate/Unidade"
                      :items="gates"
                      :item-text="
                        (item) =>
                          item.business_name +
                          ' - ' +
                          `${item.cnpj != null ? item.cnpj : item.cpf}`
                      "
                      item-value="public_id"
                      return-object
                      hint="Selecione um Gate/Unidade."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-boom-gate"
                      required
                      @change="setFornecedor()"
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    />
                    <v-text-field
                      v-else
                      v-model="empresaAtual.tenant"
                      hint="Selecione um Gate/Unidade."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-boom-gate"
                      :disabled="empresaAtual.gate"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="fornecedor"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="editedItem.fornecedor"
                      label="Fornecedor"
                      :items="selectFornecedor"
                      :item-text="
                        (item) =>
                          item.business_name +
                          ' - ' +
                          `${item.cnpj != null ? item.cnpj : item.cpf}`
                      "
                      item-value="public_id"
                      @change="selectedFornecedor()"
                      return-object
                      hint="Selecione um fornecedor."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-equalizer"
                      required
                      :disabled="
                        !empresaAtual.gate ||
                        (editedItem.public_id &&
                          ((editedItem.agendamentos &&
                            editedItem.agendamentos.length > 0) ||
                            (editedItem.programacoes_fornecedor &&
                              editedItem.programacoes_fornecedor.length > 0)))
                      "
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="tipo_operacao"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="editedItem.tipo_operacao"
                      label="Tipo de Operação"
                      :items="tiposOperacao"
                      item-text="descricao"
                      item-value="id"
                      return-object
                      hint="Selecione o tipo de operação."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-clipboard-flow-outline"
                      required
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="terminal"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="editedItem.terminal"
                      label="Terminal"
                      :item-text="
                        (item) =>
                          item.business_name +
                          ' - ' +
                          `${item.cnpj != null ? item.cnpj : item.cpf}`
                      "
                      item-value="public_id"
                      :items="selectTerminal"
                      return-object
                      hint="Selecione um terminal."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-alpha-t-box-outline"
                      required
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-divider class="my-5"></v-divider>
              <v-row>
                <v-col class="pt-0" cols="12" sm="4">
                  <validation-provider v-slot="{ errors }" name="tipo_controle">
                    <v-autocomplete
                      class="mt-3"
                      v-model="editedItem.tipo_controle"
                      label="Tipo de controle"
                      :error-messages="errors"
                      :items="tiposControle"
                      item-text="descricao"
                      item-value="id"
                      hint="Como os tipos de veículos será utilizado."
                      persistent-hint
                      prepend-icon="mdi-clipboard-alert-outline"
                      return-object
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col class="pt-0" cols="12" sm="4">
                  <validation-provider v-slot="{ errors }" name="modalidades">
                    <v-autocomplete
                      v-model="editedItem.modalidades"
                      label="Tipos de Veículo"
                      :items="modalidades"
                      :error-messages="errors"
                      item-text="descricao"
                      item-value="id"
                      multiple
                      chips
                      hint="Selecione o tipo de veículo."
                      persistent-hint
                      prepend-icon="mdi-truck-outline"
                      return-object
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col class="pt-0" cols="12" sm="4">
                  <validation-provider v-slot="{ errors }" name="carrocerias">
                    <v-autocomplete
                      v-model="editedItem.carrocerias"
                      label="Tipos de carroceria"
                      :items="carrocerias"
                      :error-messages="errors"
                      item-text="descricao"
                      item-value="id"
                      multiple
                      chips
                      hint="Selecione o tipo de carroceria."
                      persistent-hint
                      prepend-icon="mdi-truck-outline"
                      return-object
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col class="pt-0" cols="12" sm="12">
                  <validation-provider v-slot="{ errors }" name="observacao">
                    <v-text-field
                      v-model="editedItem.observacao"
                      :error-messages="errors"
                      label="Observação"
                      prepend-icon="mdi-note-text-outline"
                      hint="Digite uma observação."
                      persistent-hint
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="produto"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="editedItem.produto"
                      label="Produto"
                      item-text="descricao"
                      item-value="id"
                      :items="selectProduto"
                      @change="selectedProduto()"
                      return-object
                      hint="Selecione um produto."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-alpha-p-box-outline"
                      required
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="sub_produto"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="editedItem.sub_produto"
                      item-text="descricao"
                      label="Sub Produto"
                      item-value="id"
                      :items="selectSubProduto"
                      return-object
                      hint="Selecione um sub produto."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-alpha-p-circle-outline"
                      required
                      :disabled="
                        editedItem.public_id &&
                        ((editedItem.agendamentos &&
                          editedItem.agendamentos.length > 0) ||
                          (editedItem.programacoes_fornecedor &&
                            editedItem.programacoes_fornecedor.length > 0))
                      "
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="qt_peso"
                    rules="required"
                  >
                    <v-col class="pt-0" cols="12"
                      ><v-text-field
                        type="number"
                        v-model="editedItem.qt_peso"
                        :disabled="editedItem.qt_caminhoes != 0"
                        label="Toneladas"
                        hint="Indique o peso em toneladas permitido."
                        :error-messages="errors"
                        persistent-hint
                        prepend-icon="mdi-weight"
                      ></v-text-field>
                    </v-col>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="qt_caminhoes"
                    rules="required"
                  >
                    <v-text-field
                      type="number"
                      v-model="editedItem.qt_caminhoes"
                      :disabled="editedItem.qt_peso != 0"
                      label="Caminhões"
                      hint="Indique a quantidade de caminhões permitida."
                      :error-messages="errors"
                      persistent-hint
                      prepend-icon="mdi-truck-check-outline"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-divider class="my-5"></v-divider>
              <v-row>
                <v-col cols="12" md="6" lg="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="data_inicio"
                    rules="required"
                  >
                    <div class="mt-5 d-flex justify-space-around">
                      <label>Data inicial:</label>
                      <input
                        type="datetime-local"
                        name="datetime"
                        v-model="editedItem.data_inicio"
                        :error-messages="errors"
                        required
                      />
                    </div>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="data_fim"
                    rules="required"
                  >
                    <div class="mt-5 d-flex justify-space-around">
                      <label>Data final:</label>
                      <input
                        type="datetime-local"
                        name="datetime"
                        v-model="editedItem.data_fim"
                        :error-messages="errors"
                        required
                      />
                    </div>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12" lg="6">
                  <label>Replicar:</label>
                  <br />
                  <v-btn-toggle v-model="replicar" multiple>
                    <v-btn :disabled="!replicavel(DAYS.PRIMEIRO)">
                      {{ texto(DAYS.PRIMEIRO, editedItem) }}
                    </v-btn>
                    <v-btn :disabled="!replicavel(DAYS.SEGUNDO)">
                      {{ texto(DAYS.SEGUNDO, editedItem) }}
                    </v-btn>
                    <v-btn :disabled="!replicavel(DAYS.TERCEIRO)">
                      {{ texto(DAYS.TERCEIRO, editedItem) }}
                    </v-btn>
                    <v-btn :disabled="!replicavel(DAYS.QUARTO)">
                      {{ texto(DAYS.QUARTO, editedItem) }}
                    </v-btn>
                    <v-btn :disabled="!replicavel(DAYS.QUINTO)">
                      {{ texto(DAYS.QUINTO, editedItem) }}
                    </v-btn>
                    <v-btn :disabled="!replicavel(DAYS.SEXTO)">
                      {{ texto(DAYS.SEXTO, editedItem) }}
                    </v-btn>
                    <v-btn :disabled="!replicavel(DAYS.SETIMO)">
                      {{ texto(DAYS.SETIMO, editedItem) }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-card-text>
            <hr class="mx-5" />
            <v-card-actions>
              <v-btn
                color="red darken-1"
                v-if="editavel && notSuspended && canDelete"
                text
                @click="del(selectedEvent)"
                >Deletar</v-btn
              >
              <v-spacer></v-spacer>
              <router-link
                v-if="editavel && notSuspended"
                :to="{
                  name: 'NovoAgendamento',
                  params: {
                    ...paramsToAgendamento(editedItem),
                  },
                }"
              >
                <v-btn
                  color="primary"
                  v-if="
                    !editavel &&
                    !notSuspended &&
                    qt_peso(editedItem) == 0 &&
                    qt_caminhoes(editedItem) == 0
                  "
                  small
                  >Novo Agendamento</v-btn
                >
              </router-link>
              <v-btn text @click="detalhesDistribuicao(editedItem)">
                Agendamentos
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn v-if="canUpdate" color="red darken-1" text @click="close"
                >Cancelar</v-btn
              >
              <v-btn
                color="blue darken-1"
                v-if="notSuspended && canUpdate"
                :disabled="
                  invalid ||
                  (editavel && !editedItem.public_id) ||
                  !notSuspended
                "
                text
                @click="save"
                >Salvar</v-btn
              >
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
      <v-overlay :value="carregaDetalhes">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="detalhar">
      <detalhes-programacao
        :detalhes="detalhes"
        :headers="headersDetalhes"
        @close="() => (detalhar = false)"
      />
    </v-dialog>
  </div>
</template>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import SuspensaoProgramacaoUnidade from '@/components/patio/Programacao/Unidade/Suspensao.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'

import {
  paramsToAgendamento,
  contabilizadores,
  pesquisa,
} from '@/mixins/cotas.js'
import { parseDate } from '@/mixins/convertion.js'
import Perfil from '@/utils/perfil'
import {
  DAYS,
  TIME_DAYS,
  TIME_MINS,
  WEEKDAYS,
  date2iso,
  getDateMinMax,
} from '@/mixins/days.js'
import ContratoAPI from '@/services/tenants/contratos'
export default {
  name: 'CalendarioProgramacaoUnidade',
  // props: ['params'],
  mixins: [paramsToAgendamento, contabilizadores, pesquisa, parseDate],
  components: {
    SuspensaoProgramacaoUnidade,
    DetalhesProgramacao,
  },
  data() {
    return {
      detalhes: [],
      detalhar: false,
      headersDetalhes: [
        { text: 'Status', value: 'status' },
        { text: 'Placa', value: 'veiculo_placa' },
        { text: 'Motorista', value: 'motorista_name' },
        { text: 'Cliente', value: 'cliente_name' },
        { text: 'Região', value: 'regiao_sigla' },
        { text: 'Data do Agendamento', value: 'agendado_em' },
      ],
      carregaDetalhes: false,
      canMove: false,
      type: 'week',
      types: [
        { text: 'Mês', value: 'month' },
        { text: 'Semana', value: 'week' },
        { text: 'Dia', value: 'day' },
      ],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [
        DAYS.DOMINGO,
        DAYS.SEGUNDA,
        DAYS.TERCA,
        DAYS.QUARTA,
        DAYS.QUINTA,
        DAYS.SEXTA,
        DAYS.SABADO,
      ],
      weekdays: [
        {
          text: 'Domingo - Sábado',
          value: [
            DAYS.DOMINGO,
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
            DAYS.SABADO,
          ],
        },
        {
          text: 'Segunda - Sexta',
          value: [
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
          ],
        },
      ],
      value: '',
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      gates: [],
      selectFornecedor: [],
      selectProduto: [],
      selectSubProduto: [],
      selectTerminal: [],
      replicar_possibilidades: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      replicar: [],
      editedItem: {
        id: null,
        public_id: null,
        proprietario: null,
        fornecedor: null,
        produto: null,
        sub_produto: null,
        terminal: null,
        data_inicio: '',
        data_fim: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        tipo_operacao: null,
        modalidades: [],
        carrocerias: [],
        tipo_controle: null,
        observacao: '',
        original: null,
        copias: [],
        suspendido: 0,
        suspensoes: [
          {
            descricao: '',
            public_id: '',
            public_id_objeto: '',
            model: 'ProgramacaoUnidade',
            created: '',
            created_by: null,
          },
        ],
      },
      defaultItem: {
        id: null,
        public_id: null,
        proprietario: null,
        fornecedor: null,
        produto: null,
        sub_produto: null,
        terminal: null,
        data_inicio: '',
        data_fim: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        tipo_operacao: null,
        modalidades: [],
        carrocerias: [],
        tipo_controle: null,
        observacao: '',
        original: null,
        copias: [],
        suspendido: 0,
        programacoes_fornecedor: [],
        suspensoes: [
          {
            public_id: '',
            descricao: '',
            public_id_objeto: '',
            model: 'ProgramacaoUnidade',
            created: '',
            created_by: null,
          },
        ],
      },
    }
  },
  computed: {
    ...mapState('patio', [
      'programacaoUnidade',
      // 'programacoesUnidade',
      'terminais',
      'tiposOperacao',
      'modalidades',
      'carrocerias',
      'tiposControle',
    ]),
    ...mapState('subProdutos', ['subProdutos']),
    ...mapState('produtos', ['produtos']),
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('programacao', ['programacoesUnidade', 'params']),

    editavel() {
      if (this.editedItem.public_id != null) {
        if (new Date(this.editedItem.data_fim) > new Date()) {
          return true
        }
      }
      return false
    },
    notSuspended() {
      if (this.editedItem.public_id != null) {
        return this.editedItem.suspendido == false
      }
      return true
    },
    allowedToAdd() {
      return this.empresaAtual.gate
    },

    formTitle() {
      return this.editedItem.public_id == null
        ? 'Nova Programação'
        : 'Editar Programação'
    },
    permission() {
      return Sequences.CotasUnidade.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    this.DAYS = DAYS
    this.WEEKDAYS = WEEKDAYS
    this.TIME_DAYS = TIME_DAYS
    this.TIME_MINS = TIME_MINS
    this.date2iso = date2iso

    await this.atualizarListaTiposControle()
    await this.atualizarListaModalidades()
    await this.atualizarListaCarrocerias()
    await this.getTipoOperacao()
    if (!this.empresaAtual.gate) {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = [...possible_gates]
    } else {
      this.gates = [this.empresaAtual]
    }
  },
  watch: {
    programacoesUnidade: function () {
      this.getEvents()
    },
    editedItem: function () {
      // this.selectedEvent.start = new Date(this.editedItem.data_inicio).getTime()
      // this.selectedEvent.end = new Date(this.editedItem.data_fim).getTime()
    },
  },
  methods: {
    ...mapActions('patio', [
      'deleteProgramacaoUnidade',
      'getTiposControle',
      'getCarrocerias',
      'getModalidades',
      'getTipoOperacao',
    ]),
    ...mapActions('fornecedores', ['getFornecedoresByParams']),
    ...mapMutations('patio', ['setTerminal']),
    ...mapMutations('produtos', ['setProduto']),
    ...mapMutations('subProdutos', ['setSubProduto']),

    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('programacao', [
      'getProgramacoesUnidade',
      'getProgramacaoUnidade',
      'editProgramacaoUnidade',
      'addProgramacaoUnidade',
      'getProgramacaoDetalhe',
    ]),
    async detalhesDistribuicao(item) {
      try {
        this.carregaDetalhes = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Unidade',
          public_id: item.public_id,
        })
        this.detalhes = data
        this.detalhar = true
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregaDetalhes = false
      }
    },

    async setFornecedor() {
      if (!this.empresaAtual.gate) {
        if (this.editedItem.proprietario) {
          // const fornecedor = this.editedItem.proprietario.fornecedores.find(
          //   (f) => f.fornecedor_id === this.empresaAtual.public_id
          // )
          const fornecedores = await this.getFornecedoresByParams({
            empresa: this.editedItem.proprietario,
            params: {
              fornecedor: this.empresaAtual.public_id,
            },
          })
          const fornecedor = fornecedores.find(
            (f) => f.fornecedor_id === this.empresaAtual.public_id
          )
          this.selectFornecedor = [fornecedor]
          this.editedItem.fornecedor = fornecedor
        }
      } else {
        await this.atualizarListaFornecedores()
      }
      this.selectedFornecedor()
    },
    texto(ordem, programacao) {
      let dia = new Date(
        programacao.original
          ? programacao.original.data_inicio
          : programacao.data_inicio
      )
      let result = dia.setDate(dia.getDate() + ordem)

      return WEEKDAYS[new Date(result).getDay()]
    },
    replicavel(dia) {
      return this.replicar_possibilidades[dia]
    },
    handleGerarTriagem(agendamento) {
      this.$router.push({
        name: 'Triagens',
        params: {
          agendamento,
        },
      })
    },
    async editItem(item) {
      if (item.public_id) {
        item = await this.getProgramacaoUnidade(item.public_id)
      }
      this.replicar = []
      Object.assign(this.editedItem, item)
      if (this.editedItem.suspensoes.length == 0) {
        this.editedItem.suspensoes = this.defaultItem.suspensoes
      }
      if (this.empresaAtual.gate) {
        this.editedItem.proprietario = this.empresaAtual
      }
      this.setFornecedor()
      this.editReplicas()
      let start = new Date(this.selectedEvent.start)
      let end = new Date(this.selectedEvent.end)
      this.editedItem.data_inicio = this.date2iso(start)
      this.editedItem.data_fim = this.date2iso(end)
    },
    editReplicas() {
      if (this.editedItem.public_id == null) {
        this.replicar_possibilidades = [
          false,
          true,
          true,
          true,
          true,
          true,
          true,
        ]
      } else {
        this.replicar_possibilidades = [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ]
        let copias
        if (this.editedItem.original != null) {
          copias = this.editedItem.original.copias
          if (
            copias.findIndex(
              (element) =>
                element.public_id == this.editedItem.original.public_id
            ) == -1
          ) {
            let original = { ...this.editedItem.original }
            delete original.copias
            copias.push(original)
          }
          let index = copias.findIndex(
            (element) => element.public_id == this.editedItem.public_id
          )
          if (index > -1) {
            copias.splice(index, 1)
          }
        } else {
          copias = this.editedItem.copias
        }

        let original = this.editedItem.original
          ? this.editedItem.original
          : this.editedItem
        let original_data = new Date(original.data_inicio)

        copias.forEach((copia) => {
          let prog = this.programacoesUnidade.find(
            (element) => element.public_id == copia.public_id
          )
          if (prog != undefined) {
            let data = new Date(prog.data_inicio)
            if (data > new Date() && !prog.suspendido) {
              let posicao =
                (new Date(this.parseDate(data)) -
                  new Date(this.parseDate(original_data))) /
                (1000 * 60 * 60 * 24)
              this.replicar_possibilidades[posicao] = true
            }
          }
        })
      }
    },
    close() {
      this.selectedOpen = false
      setTimeout(() => {
        this.selectedEvent.item = { ...this.editedItem }
        Object.assign(this.editedItem, this.defaultItem)
      }, 100)
    },

    validaForm() {
      if (this.editedItem.fornecedor == null) {
        this.errorMessage('Campo Fornecedor é obrigatório.')
        return 0
      }
      if (this.editedItem.terminal == null) {
        this.errorMessage('Campo Terminal é obrigatório.')
        return 0
      }
      return 1
    },
    async save() {
      try {
        let original
        original = null
        if (this.editedItem.public_id != null) {
          original = await this.editProgramacaoUnidade(this.editedItem)
          if (this.replicar.length > 0) {
            await this.editarReplicas(original)
          }
        } else {
          original = await this.addProgramacaoUnidade(this.editedItem)
          this.selectedEvent.color = this.colors[2]
          if (this.replicar.length > 0) {
            await this.cadastrarReplicas(original)
          }
        }
        this.atualizarListaProgramacoesUnidade()
        this.close()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage(
            'Empresa atual não pode cadastrar/editar programações.'
          )
        } else {
          this.errorMessage(error.response ? error.response.data : error)
        }
      }
    },

    async editarReplicas(edited) {
      let original = edited.original ? edited.original : edited
      let original_data = new Date(original.data_inicio)

      await Promise.all(
        this.replicar.map(async (dia) => {
          try {
            // A váriavel "prog" vai receber alguma programação fornecedor onde
            // o public_id seja de uma réplica sua ou do seu original
            // ou seja o próprio original
            // cujo o dia da semana seja igual ao escolhido para replicar(SEGUNDA, TERÇA, etc)
            let prog = this.programacoesUnidade.find((element) => {
              let data = new Date(element.data_inicio)
              let posicao =
                (new Date(this.parseDate(data)) -
                  new Date(this.parseDate(original_data))) /
                (1000 * 60 * 60 * 24)
              return (
                ((edited.original
                  ? edited.original.copias
                  : edited.copias
                ).findIndex((el) => el.public_id == element.public_id) != -1 ||
                  (edited.original
                    ? edited.original.public_id == element.public_id
                    : 0)) &&
                posicao == dia
              )
            })
            if (prog) {
              let item = { ...this.editedItem }
              item.original = prog.original
              item.public_id = prog.public_id
              item.id = prog.id
              let start = new Date(item.data_inicio)
              let end = new Date(item.data_fim)
              // let diff = start.getDay() - dia
              let diff =
                (new Date(this.parseDate(start)) -
                  new Date(this.parseDate(original_data))) /
                  (1000 * 60 * 60 * 24) -
                dia
              if (diff != 0) {
                item.data_inicio = this.date2iso(
                  new Date(start - TIME_DAYS(diff))
                )
                item.data_fim = this.date2iso(new Date(end - TIME_DAYS(diff)))
                await this.editProgramacaoUnidade(item)
              }
            }
          } catch (error) {
            if (
              error.response &&
              (error.response.status == 403 || error.response.status == 404)
            ) {
              this.errorMessage(
                'Empresa atual não pode cadastrar/editar programações.'
              )
            } else {
              this.errorMessage(error.response ? error.response.data : error)
            }
          }
        })
      )
    },

    async cadastrarReplicas(original) {
      await Promise.all(
        this.replicar.map(async (element) => {
          try {
            let item = { ...this.editedItem }
            item.original = original
            let start = new Date(item.data_inicio)
            let end = new Date(item.data_fim)
            if (element > 0) {
              item.data_inicio = this.date2iso(
                new Date(start.getTime() + TIME_DAYS(element))
              )
              item.data_fim = this.date2iso(
                new Date(end.getTime() + TIME_DAYS(element))
              )
              this.events.push({
                item: item,
                name: this.createName(item),
                color: this.colors[3],
                start: start.getTime() + TIME_DAYS(element),
                end: end.getTime() + TIME_DAYS(element),
                timed: true,
              })
              await this.addProgramacaoUnidade(item)
            }
          } catch (error) {
            if (
              error.response &&
              (error.response.status == 403 || error.response.status == 404)
            ) {
              this.errorMessage(
                'Empresa atual não pode cadastrar/editar programações.'
              )
            } else {
              this.errorMessage(error.response ? error.response.data : error)
            }
          }
        })
      )
    },

    async del(item) {
      let i = this.events.findIndex(
        (elem) => elem.item.public_id == item.item.public_id
      )
      if (item.item.suspendido) {
        this.errorMessage('Não é possível deletar programações suspendidas.')
        return 0
      }
      if (item.item.public_id != null) {
        try {
          await this.deleteProgramacaoUnidade(item.item)
          if (i >= 0) {
            this.events.splice(i, 1)
          }
        } catch (error) {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      } else {
        if (i >= 0) {
          this.events.splice(i, 1)
        }
      }
      this.close()
    },
    async atualizarListaModalidades() {
      try {
        await this.getModalidades()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
    async atualizarListaCarrocerias() {
      try {
        await this.getCarrocerias()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
    async atualizarListaTiposControle() {
      try {
        await this.getTiposControle()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não é gate para ter fornecedores.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    async atualizarListaFornecedores() {
      try {
        const fornecedores = await this.getFornecedoresByParams({
          empresa: this.editedItem.proprietario,
        })
        this.selectFornecedor = [...fornecedores]
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não é gate para ter fornecedores.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    async atualizarListaProgramacoesUnidade(params = null) {
      try {
        if (params == null) {
          params = this.params
        }
        let filtros = this.pesquisa(params)
        if (!filtros.data_inicio) {
          filtros.data_inicio = new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).setDate(
              new Date().getDate() - new Date().getDay()
            )
          )
            .toISOString()
            .split('T')[0]
        }
        await this.getProgramacoesUnidade(filtros)
        this.events = []
        this.getEvents()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso as programações.')
        } else {
          console.log(error)
          this.errorMessage(error.response.data)
        }
      }
    },
    async selectedFornecedor() {
      if (this.editedItem.fornecedor != null) {
        if (this.editedItem.fornecedor.public_id != null) {
          this.setProduto(this.editedItem.fornecedor.produtos)
          this.selectProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.produtos,
          ]
          this.setTerminal(
            await ContratoAPI.getContratos(
              this.editedItem.fornecedor.fornecedor_id,
              {
                perfil: Perfil.Terminal,
              }
            )
          )
          this.selectTerminal = [...this.terminais]
        } else {
          this.selectProduto = [{ descricao: 'Nenhum', id: null }]
          this.selectTerminal = []
          this.editedItem.produto = null
          this.editedItem.sub_produto = null
          this.editedItem.terminal = null
        }
      } else {
        this.selectProduto = [{ descricao: 'Nenhum', id: null }]
        this.selectTerminal = []
        this.editedItem.produto = null
        this.editedItem.sub_produto = null
        this.editedItem.terminal = null
      }
      this.selectedProduto()
    },
    selectedProduto() {
      if (this.editedItem.produto != null) {
        if (this.editedItem.produto.id != null) {
          this.setSubProduto(this.editedItem.produto.subprodutos)
          this.selectSubProduto = [
            { descricao: 'Nenhum', id: null },
            ...this.subProdutos,
          ]
        } else {
          this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
          this.editedItem.sub_produto = null
        }
      } else {
        this.selectSubProduto = [{ descricao: 'Nenhum', id: null }]
        this.editedItem.sub_produto = null
      }
    },
    createName(programacao) {
      let name = ''
      name += programacao.fornecedor
        ? programacao.fornecedor.trade_name
          ? programacao.fornecedor.trade_name
          : programacao.fornecedor.fornecedor_trade_name
        : ''
      if (programacao.produto) {
        name += ' - ' + programacao.produto.descricao
      }
      if (programacao.sub_produto) {
        name += ' - ' + programacao.sub_produto.descricao
      }
      if (programacao.qt_caminhoes) {
        name += ' - ' + programacao.qt_caminhoes + ' Cam.'
      }
      if (programacao.qt_peso) {
        name += ' - ' + programacao.qt_peso + ' Ton.'
      }
      name += programacao.tipo_operacao
        ? programacao.tipo_operacao.descricao
        : ''
      return name
    },
    getEvents() {
      this.programacoesUnidade.forEach((element) => {
        let i = this.events.findIndex(
          (event) => event.item.public_id == element.public_id
        )
        let start = new Date(element.data_inicio)
        let end = new Date(element.data_fim)
        if (i == -1) {
          this.events.push({
            item: element,
            name: this.createName(element),
            color:
              element.suspendido == 1
                ? 'red'
                : element.tipo_operacao
                ? element.tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start,
            end: end,
            timed: true,
          })
        } else {
          this.events[i] = {
            item: element,
            name: this.createName(element),
            color:
              element.suspendido == 1
                ? 'red'
                : element.tipo_operacao
                ? element.tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start,
            end: end,
            timed: true,
          }
        }
      })
    },
    setToday() {
      this.value = ''
    },

    startDrag({ event, timed }) {
      if (this.canCreate) {
        if (event && timed) {
          this.dragEvent = event
          this.dragTime = null
          this.extendOriginal = null
        }
      }
    },
    startTime(tms) {
      if (this.canCreate) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime === null) {
          const start = this.dragEvent.start

          this.dragTime = mouse - start
        } else {
          this.createStart = this.roundTime(mouse)
          const date = getDateMinMax(new Date(this.createStart))
          this.createEvent = {
            item: this.defaultItem,
            name: `Programação`,
            color: this.colors[0],
            start: date.min.getTime(),
            end: date.max.getTime(),
            timed: true,
          }
          this.createEvent.item['id_event'] = [...this.events].length
          this.events.push(this.createEvent)
        }
      }
    },
    extendBottom(event) {
      if (this.canCreate) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        if (this.canMove) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
          this.dragEvent.color = this.colors[1]

          const date = getDateMinMax(new Date(this.dragEvent.start))
          this.dragEvent.start = date.min.getTime()
          this.dragEvent.end = date.max.getTime()
        }
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
        this.createEvent.color = this.colors[1]

        const date = getDateMinMax(new Date(this.createEvent.start))
        this.createEvent.start = date.min.getTime()
        this.createEvent.end = date.max.getTime()
      }
    },
    endDrag() {
      if (this.createEvent && this.createEvent.start == this.createEvent.end) {
        this.cancelDrag()
      }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.events.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime()
    },
    async showEvent({ nativeEvent, event }) {
      if (this.canUpdate) {
        const open = async () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          await this.editItem(event.item)
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          )
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() =>
            requestAnimationFrame(async () => await open())
          )
        } else {
          await open()
        }

        nativeEvent.stopPropagation()
      }
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16)
      const r = (rgb >> 16) & 0xff
      const g = (rgb >> 8) & 0xff
      const b = (rgb >> 0) & 0xff

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color
    },
  },
}
</script>
