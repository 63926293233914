<template>
  <div>
    <v-dialog v-model="dialog" max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn v-if="canDelete" color="error" dark class="mb-2" v-on="on">{{
          programacaoUnidade.suspendido == false ? 'Suspender' : 'Suspensão'
        }}</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.descricao"
                  label="Descrição"
                  required
                >
                  <template v-slot:label>
                    <div>Descrição <small>(Motivo da suspensão)</small></div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            v-if="editedItem.public_id != ''"
            text
            @click="del(editedItem)"
            >Liberar Suspensão</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="editedItem.descricao == ''"
            @click="save"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'SuspensaoProgramacaoUnidade',
  props: ['programacaoUnidade'],
  data() {
    return {
      dialog: false,
      editedItem: this.programacaoUnidade.suspensoes[0],
      defaultItem: {
        public_id: '',
        public_id_objeto: '',
        descricao: '',
        proprietario: null,
        model: 'ProgramacaoUnidade',
        suspendido: null,
        created: '',
        created_by: null,
      },
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    suspendido() {
      return this.programacaoUnidade.suspendido
    },
    allowedToAdd() {
      return this.empresaAtual.gate
    },
    formTitle() {
      return this.programacaoUnidade.suspendido
        ? 'Editar Suspensão'
        : 'Cadastrar Suspensão'
    },
    permission() {
      return Sequences.CotasUnidade.toString()
    },

    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  methods: {
    ...mapActions('patio', [
      'addSuspensao',
      'editSuspensao',
      'removerSuspensao',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    close() {
      this.dialog = false
      // setTimeout(() => {
      //   Object.assign(this.editedItem, this.defaultItem)
      // }, 300)
    },

    save() {
      this.editedItem.public_id_objeto = this.programacaoUnidade.public_id
      if (this.editedItem.public_id != '') {
        this.editSuspensao(this.editedItem) // REVER.. Editar
      } else {
        this.addSuspensao(this.editedItem)
      }
      this.$emit('att')
      this.$emit('cls')
      this.close()
    },

    async del(item) {
      if (item.public_id != null) {
        try {
          await this.removerSuspensao(item)
          // this.programacaoUnidade.suspensoes.slice(1);
          // if(this.programacaoUnidade.suspensoes.length > 1){
          //   this.suspendido = true;
          // } else{
          //   this.suspendido = false;
          // }
          this.$emit('att')
          this.$emit('cls')
          this.close()
        } catch (error) {
          if (
            error.response &&
            (error.response.status == 403 || error.response.status == 404)
          ) {
            this.errorMessage(
              'Empresa atual não possui pode editar suspensões.'
            )
          } else {
            this.errorMessage(error.response.data)
          }
        }
      }
    },
  },
}
</script>
